<template>
  <div class="container" v-if="hasBids || $store.state.address.toLowerCase() !== owner.toLowerCase()">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <template v-for="bid in bids" :key="bid.currency">
              <div v-if="bids.length > 0" class="mb-3">
                Bid of <strong>{{bid.price}}</strong> {{ bid.currencySymbol }} by <MetamaskAddress v-bind:address="bid.by" short="yes"></MetamaskAddress>&nbsp;&nbsp;
                <button v-if="$store.state.address.toLowerCase() === owner.toLowerCase()" class="btn btn-primary" @click="acceptBidNft(bid)">{{bid.acceptBidLabel}}</button>
                <button v-if="bid.mine && bid.expired" class="btn btn-primary" @click="cancelBidNft(id)">Cancel bid</button>
                <button v-if="bid.mine && !bid.expired" class="btn btn-secondary" disabled>Cancel bid</button>
              </div>
            </template>
            <hr v-if="bids.length > 0 && $store.state.address.toLowerCase() !== owner.toLowerCase()" />
            <div class="input-group mb-3" v-if="$store.state.address.toLowerCase() !== owner.toLowerCase()">
              <span class="input-group-text">Bid</span>
              <input type="text" v-model="newPrice" class="form-control" placeholder="1.00" @focus="bid.showLabel = true" @blur="bid.showLabel = false" />
              <label v-bind:class="'btn ' + (currencySymbol === 'BCH' ? 'btn-primary' : 'btn-outline-primary')" @click="setCurrency('BCH')">BCH</label>
              <label v-if="tokenEnabled" v-bind:class="'btn ' + (currencySymbol === 'NXS' ? 'btn-primary' : 'btn-outline-primary')" @click="setCurrency('NXS')">NXS</label>
              <button class="btn btn-primary" @click="bidNft(currency)">{{ bidButtonLabel }}</button>
            </div>
            <div class="form-text" v-if="$store.state.address.toLowerCase() !== owner.toLowerCase() && bid.showLabel">
              Opening a bid will lock your funds for at least 24 hours, but can be left open if you wish. Cancelling your bid will be possible after the locking period.
              When someone places a higher bid, funds will be sent back to you.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetamaskAddress from "../MetamaskAddress";
import {ethers} from "ethers";
import {marketProxyCA, nexusToken} from "../../../config";
import erc721abi from "../../abi/erc721.json";
import erc20 from "../../../artifacts/contracts/Nexus.sol/Nexus.json";

export default {
  components: {MetamaskAddress},
  data() {
    return {
      nftAddress: null,
      currency: ethers.constants.AddressZero,
      currencySymbol: 'BCH',
      currencies: {},
      nftContract: null,
      marketContract: null,
      nft: {
        address: null,
        id: null
      },
      bidButtonLabel: "Bid",
      bids: [],
      newPrice: null,
      hasBids: false,
      bid: {
        hasBid: false,
        newPrice: null,
        currency: null,
        price: null,
        priceWei: null,
        by: null,
        mine: false,
        expired: false,
        timestamp: null,
        showLabel: false,
        acceptBidLabel: 'Accept bid',
      }
    }
  },
  props: ['id', 'ca', 'owner'],
  watch: {
    '$store.state.connected': function() {
      this.loadBids();
    },
    'owner': function() {
      this.nft.owner = this.owner;
    }
  },
  mounted() {
    this.currencies[ethers.constants.AddressZero] = 'BCH';
    this.loadBids();
  },
  methods: {
    loadBids: function() {
      let tokens = [ethers.constants.AddressZero];
      if(this.tokenEnabled) {
        tokens.push(nexusToken);
      }
      for(let i in tokens) {
        let _hash = ethers.utils.solidityKeccak256(
            ['address', 'uint256', 'address'],
            [this.ca, this.id, tokens[i]]
        );
        this.$store.state.marketContract.bids(_hash).then(async (bid) => {
          if (bid.price > 0) {
            let loadedBid = {};
            if(typeof this.currencies[bid.currency] == 'undefined') {
              let tokenContract = new ethers.Contract(bid.currency, erc20.abi, this.$store.state.provider);
              this.currencies[bid.currency] = await tokenContract.symbol();
            }
            loadedBid.currencySymbol = this.currencies[bid.currency];
            loadedBid.currency = bid.currency;
            loadedBid.priceWei = bid.price;
            loadedBid.price = ethers.utils.formatEther(bid.price);
            loadedBid.newPrice = ethers.utils.formatEther((Math.round(bid.price * 105) / 100).toString()).substr(0, 8);
            loadedBid.by = bid.bidder;
            loadedBid.timestamp = bid.timestamp;
            loadedBid.acceptBidLabel = 'Accept bid';
            if (bid.bidder.toLowerCase() === this.$store.state.address.toLowerCase()) {
              loadedBid.mine = true;
            }
            this.$store.state.marketContract.isExpired(_hash).then((expired) => {
              loadedBid.expired = expired;
              this.hasBids = true;
              if(loadedBid.currency == this.currency) {
                this.newPrice = loadedBid.newPrice;
              }
              this.bids.push(loadedBid);
            });
          }
        });
      }
    },
    bidNft: async function(_currency) {
      var priceWei = ethers.utils.parseEther(this.newPrice);
      let _sendValue = 0;
      if(this.currency == ethers.constants.AddressZero) {
        _sendValue = priceWei;
      } else {
        let tokenContract = new ethers.Contract(_currency, erc20.abi, this.$store.state.provider.getSigner());
        let allowance = await tokenContract.allowance(this.$store.state.address, marketProxyCA);
        if(allowance.lt(priceWei)) {
          await (await tokenContract.approve(marketProxyCA, ethers.constants.MaxUint256)).wait();
        }
      }
      this.bidButtonLabel = "Sending transaction...";
      this.$store.state.marketContract.bid(this.ca, this.id, _currency, priceWei, {value: _sendValue}).then(async (tx) => {
        await tx.wait();
        window.location.reload();
      });
    },
    acceptBidNft: function(bid) {
      this.nftContract = new ethers.Contract(this.ca, erc721abi, this.$store.state.provider.getSigner())
      this.nftContract.isApprovedForAll(this.owner, marketProxyCA).then((approved) => {
        if(approved) return;
        bid.acceptBidLabel = 'Requesting approval...';
        return this.nftContract.setApprovalForAll(marketProxyCA, true);
      }).then(() => {
        bid.acceptBidLabel = 'Accepting...';
        this.$store.state.marketContract.acceptBid(this.ca, this.id, bid.currency, bid.priceWei.toString()).then(async (tx) => {
          await tx.wait();
          window.location.reload();
        });
      });
    },
    setCurrency: function(currency) {
      this.currencySymbol = currency;
      switch(currency) {
        case 'BCH': this.currency = ethers.constants.AddressZero; break;
        case 'NXS': this.currency = nexusToken; break;
      }
      for(let i in this.bids) {
        if (this.bids[i].currency == this.currency) {
          this.newPrice = this.bids[i].newPrice;
        }
      }
    },
    cancelBidNft: function() {
      let _hash = ethers.utils.solidityKeccak256(['address', 'uint256', 'address'], [this.ca, this.id, this.currency]);
      this.$store.state.marketContract.cancelBid(_hash).then(async (tx) => {
        await tx.wait();
        window.location.reload();
      });
    },
  }
}
</script>