<template>
  <div class="container" v-if="(market.listed || market.mine || brief === 'yes')">
    <div class="row">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">
            <template v-if="market.listed && !market.mine">
              <p v-for="listing in listings" :key="listing.signature">
                <template v-if="brief != 'yes'">
                  <strong>Market:</strong><br/>
                  &nbsp;
                  <button class="btn btn-primary" @click="buyNft(listing)">Buy for {{ listing.formattedPrice }} {{ listing.currencySymbol }}</button>
                </template>

                <template v-if="brief == 'yes'">
                  <span class="text-center">
                    <MetamaskAddress v-bind:address="owner" short="yes"></MetamaskAddress><br/>
                    <button class="btn btn-primary" @click="buyNft(listing)">Buy for {{ listing.formattedPrice }} {{ listing.currencySymbol }}</button>
                  </span>
                </template>
              </p>
            </template>

            <template v-if="!market.listed && market.mine">
              <template v-if="brief != 'yes'">
                <strong>Market:</strong><br/>
                <div class="input-group mb-3">
                  <span class="input-group-text">Price: </span>
                  <input type="text" v-model="market.price" placeholder="1.00" />
                  <label v-bind:class="'btn ' + (currencySymbol === 'BCH' ? 'btn-primary' : 'btn-outline-primary')" @click="setCurrency('BCH')">BCH</label>
                  <label v-if="tokenEnabled" v-bind:class="'btn ' + (currencySymbol === 'NXS' ? 'btn-primary' : 'btn-outline-primary')" @click="setCurrency('NXS')">NXS</label>
                  <button class="btn btn-primary" @click="listNft()">{{ market.offerButtonLabel }}</button>
                </div>
                <small><i>Market fee: 2%, deducted when NFT sells. Listing is free.</i></small>
              </template>

              <p v-if="brief == 'yes'">
                <span class="text-center">
                  <input type="text" v-model="market.price" size="10" /> BCH <br/>
                  <button class="btn btn-primary" @click="listNft()">{{ market.offerButtonLabel }}</button>
                </span>
              </p>
            </template>

            <p v-for="listing in listings" :key="listing.signature">
              <template v-if="market.mine">
                <template v-if="brief != 'yes'">
                  <span>
                    <strong>Market: </strong>
                    <span>Listed for {{ listing.formattedPrice }} {{ listing.currencySymbol }}</span><br/>
                    <!--input type="text" v-model="market.newPrice" /-->
                    <!--button class="btn btn-primary" @click="changePriceNft">Change price</button-->
                    <button class="btn btn-danger" @click="cancelNft(listing)">Cancel listing</button>
                  </span>
                </template>
                <template v-if="brief == 'yes'">
                  <span class="text-center">
                    <input type="text" v-model="market.newPrice" size="10" /> {{ listing.currencySymbol }}
                    <button class="btn btn-primary" @click="changePriceNft">Change price</button>
                  </span>
                </template>
              </template>
            </p>

            <p v-if="!market.listed && !market.mine">
              <span class="text-center">
                <MetamaskAddress v-bind:address="owner" short="yes"></MetamaskAddress><br/>
                <button class="btn btn-primary" v-if="market.buttonLabel" @click="buyNft(listing)" disabled>{{ market.buttonLabel }}</button>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetamaskAddress from "../MetamaskAddress";
import {ethers} from "ethers";
import {marketProxyCA, nexusToken} from "../../../config";
import erc721abi from '../../abi/erc721.json';
import erc20 from '../../../artifacts/contracts/Nexus.sol/Nexus.json';

export default {
  components: { MetamaskAddress },
  data() {
    return {
      nftAddress: null,
      nftContract: null,
      listings: [],
      currencies: {},
      currency: ethers.constants.AddressZero,
      currencySymbol: 'BCH',
      market: {
        listed: false,
        mine: false,
        price: null,
        newPrice: null,
        priceWei: null,
        buttonLabel: null,
        owner: null,
        offerButtonLabel: 'List for sale',
      }
    }
  },
  props: ['id', 'owner', 'ca', 'brief'],
  watch: {
    '$store.state.connected'() {
      this.loadMarketData();
    },
    '$store.state.address'() {
      this.market.mine = false;
      this.loadMarketData();
    }
  },
  mounted() {
    this.loadMarketData();
  },
  methods: {
    loadMarketData: function() {
      this.currencies[ethers.constants.AddressZero] = 'BCH';
      if(this.$store.state.connected) {
        this.nftAddress = this.ca;
        this.nftContract = new ethers.Contract(this.nftAddress, erc721abi, this.$store.getters.provider);
        this.nftContract.ownerOf(this.id).then(owner => {
          if (this.$store.state.address.toLowerCase() === owner.toLowerCase()) {
            this.market.owner = owner;
            this.market.mine = true;
          }
        });

        fetch(this.api_url + '/original/listing/' + this.ca + '/' + this.id).then(result => {
          return result.json();
        }).then(listings => {
          for(let key in listings) {
            this.$store.state.marketContract.tradeCompleted(listings[key].hash).then(async completed => {
              if(completed) return;
              this.market.listed = true;
              if(typeof this.currencies[listings[key].currency] == 'undefined') {
                let tokenContract = new ethers.Contract(listings[key].currency, erc20.abi, this.$store.state.provider);
                this.currencies[listings[key].currency] = await tokenContract.symbol();
              }
              listings[key].currencySymbol = this.currencies[listings[key].currency];
              listings[key].formattedPrice = ethers.utils.formatEther(listings[key].price);
              this.listings.push(listings[key]);
            });
          }
        });
      }
    },
    setCurrency: function(currency) {
      this.currencySymbol = currency;
      switch(currency) {
        case 'BCH': this.currency = ethers.constants.AddressZero; break;
        case 'NXS': this.currency = nexusToken; break;
      }
    },
    cancelNft: function(listing) {
      console.log(listing);
      this.$store.state.marketContract.cancel(listing.owner, listing.ca, listing.tokenId, listing.currency, listing.price, listing.expires, listing.salt, listing.signature).then(async (tx) => {
        await tx.wait();
        window.location.reload();
      });
    },
    listNft : function() {
      this.nftContract = new ethers.Contract(this.ca, erc721abi, this.$store.getters.provider.getSigner());
      this.nftContract.isApprovedForAll(this.$store.state.address, marketProxyCA).then((approved) => {
        if(approved) return;
        this.market.offerButtonLabel = 'Asking approval...';
        return this.nftContract.setApprovalForAll(marketProxyCA, true);
      }).then(() => {
        this.market.offerButtonLabel = 'Signing...';
        this.market.priceWei = ethers.utils.parseEther(this.market.price);
        let _salt = ethers.utils.keccak256(ethers.utils.toUtf8Bytes('market_' + this.$store.state.address + '_' + Date.now()));
        let _values = [
          this.$store.state.address,
          this.ca,
          this.id,
          this.currency,
          this.market.priceWei.toString(),
          0,
          _salt
        ];
        let messageHash = ethers.utils.solidityKeccak256([
              "address",
              "address",
              "uint256",
              "address",
              "uint256",
              "uint256",
              "bytes32"
            ],
            _values
        );
        return this.$store.state.provider.getSigner().signMessage(ethers.utils.arrayify(messageHash)).then(signature => {
          console.log(ethers.utils.verifyMessage(ethers.utils.arrayify(messageHash), signature));
          _values.push(signature);
          _values.push(messageHash);
          fetch(this.api_url + '/original/list', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify(_values)
          }).then(() => {
            this.loadMarketData();
          }).catch(() => {
            alert('Error');
          });
        });
      });
    },
    buyNft : async function(listing) {
      let _sendValue = 0;
      if(listing.currency == ethers.constants.AddressZero) {
        _sendValue = listing.price;
      } else {
        let tokenContract = new ethers.Contract(listing.currency, erc20.abi, this.$store.state.provider.getSigner());
        let allowance = await tokenContract.allowance(this.$store.state.address, marketProxyCA);
        if(allowance.lt(listing.price)) {
          await (await tokenContract.approve(marketProxyCA, ethers.constants.MaxUint256)).wait();
        }
      }
      this.$store.state.marketContract.buy(
          listing.owner,
          listing.ca,
          listing.tokenId,
          listing.currency,
          listing.price,
          listing.expires,
          listing.salt,
          listing.signature,
          {value: _sendValue}
      ).then(async (tx) => {
        await tx.wait();
        window.location.reload();
      });
    }
  }
}
</script>